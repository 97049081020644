//head
import head from '@/assets/service/ecommerce/head/head.jpg'

//page
import api from '@/assets/service/ecommerce/page/api.jpg'
import module from '@/assets/service/ecommerce/page/module.jpg'
import spide from '@/assets/service/ecommerce/page/spide.jpg'
import login from '@/assets/service/ecommerce/page/login.jpg'
import platform from '@/assets/service/ecommerce/page/platform.jpg'

//slide
//https://huaban.com/pins/1260350820/
import erp from '@/assets/service/ecommerce/slide/erp.webp'
import drainage from '@/assets/service/ecommerce/slide/drainage.webp'
import wms from '@/assets/service/ecommerce/slide/wms.webp'
import boutique from '@/assets/service/ecommerce/slide/boutique.webp'

//tech
import functions from '@/assets/service/ecommerce/tech/functions.jpg'
import defined from '@/assets/service/ecommerce/tech/defined.jpg'
import consistent from '@/assets/service/ecommerce/tech/consistent.jpg'
import group from '@/assets/service/ecommerce/tech/group.jpg'

import fee from '@/assets/service/fee.jpg'
const data={
    head:{
        img:head,
        title:"电子商务",
        subTitle:"E-Commerce",
        items:[
            {
               icon:'erp',
               title:"电商运营系统",
               subTitle:"ERP", 
            },
            {
                icon:'truck',
                title:"仓库管理系统",
                subTitle:"WMS", 
            },
            {
                icon:'diagram',
                title:"电商引流站点",
                subTitle:"Drainage", 
            },
            {
                icon:'cart',
                title:"自营电商平台",
                subTitle:"E-commerce", 
            },
        ]
    },
    slogan:[
        {
            title:'ERP',
            subTitle:'电商平台运营管理系统',
            desc:`ERP系统已经成为国内外电商平台大卖家必不可少的运营工具。<br/>
            通过此平台可以在一个后台管理多个店铺（可跨不同平台），可以实现产品、订单、物流等数据的批量处理。<br/>
            不仅大大提高效率，同时大幅度减少人力成本的投入。`,
        },
        {
            title:'WMS',
            subTitle:'仓库管理系统',
            desc:`无论是独立自用仓库还是提供给第三方使用的仓库，WMS系统都是必不可少的。<br/>WMS上承产品入库，管理存货仓储，下接产品出库，是实现仓储自动化、智能化的基础设施。`,
        },
        {
            title:'Drainage',
            subTitle:'引流平台',
            desc:`如果您的网站拥有一定的流量，那么接入电商引流机制是实现创收的理想方案，我们为您提供多种嵌入方案，无论是全站引流还是页面模块引流都不在话下。`,
        },
        {
            title:'E-commerce',
            subTitle:'自营电商',
            desc:`自营电商是主流电商平台之外的有益补充，它集企业官网和产品销售于一体，可以不受其他平台规则的显示，基于产品特性开发更好展示产品和更好用户交互体验的用户界面。<br/>
            这不仅为销售商品，同时还是展示企业形象和产品品质的平台。`,
        }
    ],
    slide:[
        {
            tag:"Amazon",
            title:"亚马逊ERP",
            desc:"主流跨境电商平台（Amazon、eBay、Lazada等）运营ERP系统。",
            img:erp,
        },
        {
            tag:"WMS",
            title:"海外仓",
            desc:"自营海外仓WMS系统。",
            img:wms,
        },
        {
            tag:"Boutique",
            title:"精品电商",
            desc:"自营精品电商已经称为电商巨头之外的新增长点。",
            img:boutique,
        },
        {
            tag:"Drainage",
            title:"引流集市",
            desc:"集合多个电商平台热门商品、折扣商品，自营引流集市站点。",
            img:drainage,
        },
    ],
    page:{
        header:{
            title:"全球&新锐",
            subTitle:"Global & Cutting-edge",
            desc:`无论是国内电商三巨头还是国外主流电商（Amazon、Lazada、Aliexpress等）我们都全面支持。<br/>
            而且我们还关注最新流行电商平台，例如：TikTok等。`,
        },
        items:{
            main:{
                title:"弹性模块",
                subTitle:"Flexible Modules",
                desc:"各类运营平台可根据需要和企业发展逐步增加功能模块，各模块弹性可选。",
                img:module
            },
            minor:[
                {
                    title:"多平台合一",
                    subTitle:"Multi-Platform",
                    desc:"支持一个后台管理多个不同电商平台。",
                    img:platform,
                },
                {
                    title:"官方接口",
                    subTitle:"Official Apis",
                    desc:"使用官方最新API接口，保证安全性，且同步开发新功能。",
                    img:api,
                },
                {
                    title:"流畅体验",
                    subTitle:"Fluid Experience",
                    desc:"界面简洁、操作简单，功能丰富而使用流畅。",
                    img:spide,
                },
                {
                    title:"免登录",
                    subTitle:"Avoid the login",
                    desc:"一次登录长期有效",
                    img:login,
                }, 
            ]
        }
    },
    tech:{
        header:{
            title:"可配置&可统计",
            subTitle:"Setting & Statistics",
            desc:`考虑到平台差异性和接口丰富性，最好的管理后台就是基于需求的自定义配置，对于不同平台的相同功能我们开发出体验统一的管理模块。<br/>
            同时，平台内置多种数据及用户信息统计功能，且支持个性化数据统计模块开发。`,
        },
        items:[
            {
                img:{
                    path:functions,
                    direct:'right',
                    directMobile:'right',
                },
                text:{
                    title:"功能可选",
                    subTitle:"Function Selectable",
                    desc:["内置核心功能","多种辅助功能可选择","根据功能模块计费","功能模块可随时调整"]
                }
            },
            {
                img:{
                    path:consistent,
                    direct:'left',
                    directMobile:'left',
                },
                text:{
                    title:"一致体验",
                    subTitle:"Consistent Experience",
                    desc:["按功能划分操作模块","不同平台，功能相同则模块相同","操作时无平台差异","新增平台不会增加学习成本"]
                }
            },
            {
                img:{
                    path:defined,
                    direct:'left',
                    directMobile:'right',
                },
                text:{
                    title:"自定义模块",
                    subTitle:"Custom Modules",
                    desc:["根据需求单独开发","与其他模块无障碍打通","后期可升级可改造"]
                }
            },
            {
                img:{
                    path:group,
                    direct:'right',
                    directMobile:'left',
                },
                text:{
                    title:"用户隔离",
                    subTitle:"User Isolation",
                    desc:["支持管理员权限分级","支持用户分组","分级分组可操作不同模块组合"]
                }
            },
            
        ]
    },
    fee:{
        title:"计费",
        subTitle:"Price",
        img:fee,
        items:[
            {
                tag:"By Module",
                title:"按功能模块计费",
                desc:"对于ERP、WMS等平台类项目，采用按模块计费，基于您选择的模块清单来计算费用，且后期可随时调整模块清单。",
                price:"5000~10000",
                unit:"元/模块",
            },
            {
                tag:"By Category",
                title:"按分类计费",
                desc:"对于引流类网站，按照选择的产品分类数量来计费，这涉及到产品的自动获取（爬虫或API）和对应的管理后台。<br/>后期也可以动态调整。",
                price:"3000~6000",
                unit:"元/类",
            }
        ]
    }
}

export default data;