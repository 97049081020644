//head
import head from '@/assets/service/crawler/head/head.jpg'

//page
import time from '@/assets/service/crawler/page/time.jpg'
import auto from '@/assets/service/crawler/page/auto.jpg'
import hour from '@/assets/service/crawler/page/24.jpg'
import smart from '@/assets/service/crawler/page/smart.jpg'
import parallel from '@/assets/service/crawler/page/parallel.jpg'

//slide
//https://huaban.com/pins/1260350820/
import shop from '@/assets/service/crawler/slide/shop.jpg'
import stock from '@/assets/service/crawler/slide/stock.jpg'
import google from '@/assets/service/crawler/slide/google.jpg'
import facebook from '@/assets/service/crawler/slide/facebook.jpg'

//tech
import database from '@/assets/service/crawler/tech/database.jpg'
import clean from '@/assets/service/crawler/tech/clean.jpg'
import analysis from '@/assets/service/crawler/tech/analysis.jpg'
import power from '@/assets/service/crawler/tech/power.jpg'

import fee from '@/assets/service/fee.jpg'
const data={
    head:{
        img:head,
        title:"网络爬虫",
        subTitle:"Web Crawler",
        items:[
            {
               icon:'cart2',
               title:"电商爬虫",
               subTitle:"E-Commerce", 
            },
            {
                icon:'coin',
                title:"金融爬虫",
                subTitle:"Finance", 
            },
            {
                icon:'mailbox',
                title:"新闻爬虫",
                subTitle:"News", 
            },
            {
                icon:'chat',
                title:"社交爬虫",
                subTitle:"Social Media", 
            },
            {
                icon:'bug',
                title:"全能爬虫",
                subTitle:"All-round", 
            }
        ]
    },
    slogan:[
        {
            title:'E-Commerce',
            subTitle:'电商平台',
            desc:'爬取任意电商平台产品分类、详情、销量及用户评价等信息，可以作为二次开发的基础素材，也可进行热门产品排行等数据分析。',
        },
        {
            title:'Finance',
            subTitle:'金融财经',
            desc:'从股市信息网站（同花顺等）、财经门户网站（新浪财经等）以及政府信息披露网站、企业官网等平台爬取企业财报、业绩预测、股市动态等金融数据。',
        },
        {
            title:'News',
            subTitle:'新闻资讯',
            desc:'从新闻门户网站（百度等）、企业官网、政府官网等平台爬取最新或历史新闻数据，可以作为二次开发的基础素材，也可作为社交媒体发布的资源。',
        },
        {
            title:'Social Media',
            subTitle:'社交网络',
            desc:'从社交媒体平台（新浪微博、知乎等）爬取用户发布正文、用户评论以及阅读量、转发量等数据，可进行热门话题分析，也可作为自营社交账号基础素材。',
        },
        {
            title:'All-round',
            subTitle:'爬取一切',
            desc:'不论网站类型与内容格式，只需要提供一个网址（与数据过滤规则），就可实现想要获取的数据。',
        },
        
        
    ],
    slide:[
        {
            tag:"Taobao",
            title:"爬淘宝",
            desc:"可开发精品导航站，可分析热门产品排行。",
            img:shop,
        },
        {
            tag:"Weibo",
            title:"爬微博",
            desc:"可再加工后发布，可分析热门话题排行。",
            img:facebook,
        },
        {
            tag:"Report",
            title:"爬财报",
            desc:"可用来预测股价趋势，可分析行业行情。",
            img:stock,
        },
        {
            tag:"Baidu",
            title:"爬百度",
            desc:"可获取分类热点，可作为资源再加工。",
            img:google,
        },
    ],
    page:{
        header:{
            title:"智能&省心",
            subTitle:"Smart & Convenience",
            desc:"功能强大，可爬取一切的智能爬虫，使用起来也可以很省心，简单至一键启动，更可以设置定时启动，且支持多爬虫并行。",
        },
        items:{
            main:{
                title:"可自动 可手动",
                subTitle:"Automatic & Manual",
                desc:"可设置为自动启动，也可设置按钮点击启动。",
                img:auto
            },
            minor:[
                {
                    title:"24小时",
                    subTitle:"24 Hours",
                    desc:"爬虫全日无休，24小时在服务器中为您抓取数据。",
                    img:hour,
                },
                {
                    title:"定时启动",
                    subTitle:"Timing Start",
                    desc:"既可以节省资源，又不错过每一次目标数据。",
                    img:time,
                },
                {
                    title:"高效并行",
                    subTitle:"Concurrency",
                    desc:"同时启动多个（多至100个）爬虫，分类抓取，效率大增。",
                    img:parallel,
                },
                {
                    title:"智能容错",
                    subTitle:"Fault-Tolerantg",
                    desc:"多重容错机制，最大限度处理缺陷数据，保证爬虫无间断运行。",
                    img:smart,
                }, 
            ]
        }
    },
    tech:{
        header:{
            title:"抓取&统计",
            subTitle:"Crawl & Statistics",
            desc:`不仅省心，且有强大特性，支持登录授权、模拟用户操作等复杂场景。<br/>同时支持对原始数据的全套处理，包括：数据清洗、数据分析、数据存储、数据可视化等。
            <br/>不只爬取数据，更深度加工数据。`,
        },
        items:[
            {
                img:{
                    path:power,
                    direct:'right',
                    directMobile:'right',
                },
                text:{
                    title:"功能强大",
                    subTitle:"Power",
                    desc:["智能用户登录","模拟用户鼠标操作","捕获Javascript动态数据","字符编码转换"]
                }
            },
            {
                img:{
                    path:clean,
                    direct:'left',
                    directMobile:'left',
                },
                text:{
                    title:"数据清洗",
                    subTitle:"Cleaning",
                    desc:["Dom节点字符提取","正则规则匹配","数据动态拼接","可配置性条件过滤"]
                }
            },
            {
                img:{
                    path:database,
                    direct:'left',
                    directMobile:'right',
                },
                text:{
                    title:"自动存库",
                    subTitle:"Saving",
                    desc:["存库格式转换","数据表自动创建","数据自动写入数据表","多种数据库可选"]
                }
            },
            {
                img:{
                    path:analysis,
                    direct:'right',
                    directMobile:'left',
                },
                text:{
                    title:"数据分析",
                    subTitle:"Analysis",
                    desc:["内置数据统计方法","自动生成数据统计后台","多种数据可视化图表","统计Excel下载"]
                }
            },
            
        ]
    },
    fee:{
        title:"计费",
        subTitle:"Price",
        img:fee,
        items:[
            {
                tag:"By Crawler Count",
                title:"按爬虫数量计费",
                desc:"如果您的目标站点很少，那么您可以选择此收费方式。<br/>数据分析和数据统计后台作为可选增值项可选配。",
                price:"2000~8000",
                unit:"元/个",
            },
            {
                tag:"By Web Count",
                title:"按目标站点数量",
                desc:"如果您的目标站点较多，且数据结构较为复杂，那么您可以选择此收费方式。<br/>这时为了更好的使用抓取数据，我们默认为您提供数据统计后台。",
                price:"5000~15000",
                unit:"元/个",
            }
        ]
    }
}

export default data;