//head
import head from '@/assets/service/web/head/head.jpg'

//page
import multiScreen from '@/assets/service/web/page/multi-screen.webp'
import response from '@/assets/service/web/page/response.webp'
import bootstrap from '@/assets/service/web/page/bootstrap.png'
import frame from '@/assets/service/web/page/frame.jpg'
import design from '@/assets/service/web/page/design.jpg'

//slide
//https://huaban.com/pins/1260350820/
import official from '@/assets/service/web/slide/official.webp'
import ad from '@/assets/service/web/slide/ad.webp'
import cms from '@/assets/service/web/slide/cms.webp'
import visual from '@/assets/service/web/slide/visual.webp'

//tech
import database from '@/assets/service/web/tech/database.jpg'
import deploy from '@/assets/service/web/tech/deploy.jpg'
import server from '@/assets/service/web/tech/server.webp'
import test from '@/assets/service/web/tech/test.jpg'

import fee from '@/assets/service/fee.jpg'
const data={
    head:{
        img:head,
        title:"网站",
        subTitle:"Website",
        items:[
            {
               icon:'multi-screen',
               title:"多屏合一",
               subTitle:"Multi-Screen", 
            },
            {
                icon:'phone',
                title:"手机网站",
                subTitle:"Phone Website", 
            },
            {
                icon:'chat',
                title:"微信网站",
                subTitle:"Wechat Website", 
            },
            {
                icon:'laptop',
                title:"电脑网站",
                subTitle:"Desktop Website", 
            }
        ]
    },
    slogan:[
        {
            title:'Multi-Screen',
            subTitle:'一站多屏',
            desc:`基于媒体查询和前端框架的多屏合一站点已经称为新的潮流。<br/>这种模式可以让一个站点在不同的设备上，展示不同的页面内容和布局方式。
            不仅大大提升了用户体验度，同时只需维护和部署一个站点也最大限度的降低了成本。`,
        },
        {
            title:"Mobile First",
            subTitle:"移动优先",
            desc:`智能手机无疑是当前使用最广的网络终端设备，可以说手机的重要性早已超过电脑，所以大部分企业都已经将"移动优先"作为了企业的战略选择。<br/>
            如果只想（或只能）拥有一个站点，那么一定应该选择手机网站，并且手机网站拥有更多和用户直接沟通的能力，例如直接调用电话拨号、直接调用微信聊天等。`,
        },
        {
            title:"Wechat",
            subTitle:"抓住入口",
            desc:`微信依然是当前最重要的移动流量入口，在"移动优先"的基础上，"微信优先"又是绝大多数企业的战略选择。<br/>
            通过绑定微信公众号，将手机网站升级为微网站，不但有机会实现用户订阅，而且可以调用微信用户信息和丰富的微信内置功能接口。`,
        },
        {
            title:"Official",
            subTitle:"官方权威",
            desc:`虽然随着移动互联网的兴起，电脑网站的重要性有所下降，但它依然有着许多无法替代的优势。<br/>
            电脑网站相对手机网站更有权威性和可信度，所有的政府部门和大型企业门户网站依然首选电脑网站。<br/>
            电脑屏幕的尺寸和主机配置决定了它可以承载更多的数据内容并支持更炫酷和复杂的页面效果。`,
        },
    ],
    slide:[
        {
            tag:"Official Site",
            title:"企业官网",
            desc:`提升企业可信度和权威性，展示产品与服务，发布企业动态，提供联系方式。`,
            img:official,
        },
        {
            tag:"CMS",
            title:"内容管理系统",
            desc:"包括但不限于：新闻发布系统、人事管理系统、财务管理系统、流程控制系统等。",
            img:cms,
        },
        {
            tag:"ST-MM",
            title:"营销管理系统",
            desc:"包括但不限于：销售管理系统、客户管理系统、广告管理系统等。",
            img:visual,
        },
        {
            tag:"Personal Website",
            title:"个人网站",
            desc:"个人网站已经称为提升高素质人才竞争里的主要手段之一，相对于简历，网站可以全方位的展示个人作品及履历。",
            img:ad,
        },
        {
            tag:"Any Website",
            title:"定制网站",
            desc:"按照客户需求，定制开发，您可以仅仅提供一个初始想法，也可以提供完整的需求文档。",
            img:visual,
        },
    ],
    page:{
        header:{
            title:"灵活&前沿",
            subTitle:"Flexible & Frontier",
            desc:`灵活性基于我们强大的技术能力，不论您需要完全原创的界面和框架或者有明确的参考站点，您得到的都将是超预期的惊喜。<br/>
            前沿技术是我们的第一选择，不论是语言或者框架，我们都选择业界最新稳定版，这不仅可以让您的网站功能更强大，也为未来的升级做好了准备。`,
        },
        items:{
            main:{
                title:"可原创 可高仿",
                subTitle:"Original & High-copy",
                desc:"首选原创，但也支持高仿，我们的高仿不是抄袭代码，而是用原生代码实现相同的界面与交互。",
                img:design
            },
            minor:[
                {
                    title:"响应式设计",
                    subTitle:"Responsive",
                    desc:"完全摒弃过时的固定宽度设计，所有页面实现按屏幕自适应。",
                    img:response,
                },
                {
                    title:"多屏适配",
                    subTitle:"Multi-Screen",
                    desc:"提供媒体查询样式和独立组件两种模式的多屏适配方案，实现多屏合一。",
                    img:multiScreen,
                },
                {
                    title:"前沿技术",
                    subTitle:"Frontier",
                    desc:"支持最新的Vue3.0/Typescript等最新框架和开发语言。",
                    img:bootstrap,
                },
                {
                    title:"可选框架",
                    subTitle:"Optional Frame",
                    desc:"服务器端开发语言和框架可自由选择: Laravel,Express,Flask,Spring Boot...",
                    img:frame,
                }, 
            ]
        }
    },
    tech:{
        header:{
            title:"安全&稳定",
            subTitle:"Safety & Stabilization",
            desc:`使用行业领先的（阿里云、亚马逊）云服务，可配置、高弹性数据库与服务器。<br/>支持分布式部署、数据库自动备份，支持服务器安全自动扫描。
            <br/>提供3种部署环境（SIT、UAT、Pro），提供上线前饱和测试，支持Bug快速响应、快速修复，紧急漏最快2小时内解决。`,
        },
        items:[
            {
                img:{
                    path:database,
                    direct:'right',
                    directMobile:'right',
                },
                text:{
                    title:"云数据库",
                    subTitle:"Cloud Database",
                    desc:["自动备份","按年购买/自选存储空间","关系型数据库/noSQL数据库","阿里云/亚马逊云/华为云"]
                }
            },
            {
                img:{
                    path:server,
                    direct:'left',
                    directMobile:'left',
                },
                text:{
                    title:"云服务器",
                    subTitle:"Cloud Server",
                    desc:["自选系统","按年购买/自选空间及宽带","分布式部署/海外空间","阿里云/亚马逊云/华为云"]
                }
            },
            {
                img:{
                    path:deploy,
                    direct:'right',
                    directMobile:'right',
                },
                text:{
                    title:"三级部署",
                    subTitle:"3 Levels Deployment",
                    desc:["SIT: 单元测试","UAT：集成测试","Pro: 生产环境"]
                }
            },
            {
                img:{
                    path:test,
                    direct:'left',
                    directMobile:'left',
                },
                text:{
                    title:"饱和测试",
                    subTitle:"Saturation Testing",
                    desc:["资深程序测试员","三级部署前测试","测试通过率合格可部署","上线后验证测试"]
                }
            },
            
        ]
    },
    fee:{
        title:"计费",
        subTitle:"Price",
        img:fee,
        items:[
            {
                tag:"By Page",
                title:"按页面计费",
                desc:"如果您更关心页面效果，而对具体的开发框架和代码实现无特别要求，<br/>您可以选择此计费方式，我们将根据页面功能项和复杂度提供每一个页面的价格表。",
                price:"2000~4000",
                unit:"元/页",
            },
            {
                tag:"By Time",
                title:"按时间计费",
                desc:"如果您更关心代码质量或对开发工具有特别要求，又或者您有明确的开发进度表，<br/>您可以选择此计费方式，我们将提供详细的开发计划，并对每个开发周期提供价格表。",
                price:"10000",
                unit:"元/月",
            }
        ]
    }
}

export default data;