//head
import head from '@/assets/service/wechat/head/head.jpg'

//page
import customization from '@/assets/service/wechat/page/customization.png'
import activity from '@/assets/service/wechat/page/activity.png'
import statistics from '@/assets/service/wechat/page/statistics.png'
import theme from '@/assets/service/wechat/page/theme.png'
import spide from '@/assets/service/wechat/page/spide.png'

//slide
//https://huaban.com/pins/1260350820/
import QRCodePay from '@/assets/service/wechat/slide/QRCode-Pay.jpg'
import ActivityMarketing from '@/assets/service/wechat/slide/Activity-Marketing.jpg'
import OnlineOrder from '@/assets/service/wechat/slide/Online-Order.jpg'
import OnlineService from '@/assets/service/wechat/slide/Online-Service.jpg'

//tech
import life from '@/assets/service/wechat/tech/life.jpg'
import retail from '@/assets/service/wechat/tech/retail.png'
import catering from '@/assets/service/wechat/tech/catering.png'
import trip from '@/assets/service/wechat/tech/trip.jpg'

import fee from '@/assets/service/fee.jpg'
const data={
    head:{
        img:head,
        title:"微信",
        subTitle:"Wechat",
        items:[
            {
               icon:'min-program',
               title:"小程序",
               subTitle:"Mini Program", 
            },
            {
                icon:'official-account',
                title:"公众号",
                subTitle:"Official Accounts", 
            },
            
            {
                icon:'wechat-pay',
                title:"微信支付",
                subTitle:"Wechat Pay", 
            },
            {
                icon:'business-service',
                title:"商务服务",
                subTitle:"Business Services", 
            },
        ]
    },
    slogan:[
        {
            title:'Mini Program',
            subTitle:'小程序',
            desc:'微信小程序已经称为当前最流行的App轻应用，依托微信、免安装等特点让它称为本地服务类小应用的首选。',
        },
        {
            title:'Official Account',
            subTitle:'公众号',
            desc:'相对于小程序，公众号开发门槛更低，而且可以把普通的手机网站改造为微网站，不仅可以使用微信接口，还可以设置定时推送消息等服务。',
        },
        {
            title:'Mini Shop',
            subTitle:'小店铺',
            desc:'微信小店铺是专门为线上销售企业提供的平台，相对于简单直接的小程序，小店铺更适合中大型零售企业使用。',
        },
        {
            title:'Pay',
            subTitle:'微信支付',
            desc:'微信支付已经称为当下的社会基础设施，基于微信支付可以开发海量的、各个细分行业的业务功能，应用场景极大。',
        }
    ],
    slide:[
        {
            tag:"QRCode Pay",
            title:"扫码支付",
            desc:"顾客通过扫码购、自助收银等服务，运用微信大数据，对用户进行精准触达，实现会员、营销、导购的一站式运营。",
            img:QRCodePay,
        },
        {
            tag:"Online Order",
            title:"在线点单",
            desc:"微信扫码点餐助力餐饮行业“免排队，轻松点”，通过精准营销，提高门店效率，增加粘性，助力餐饮行业智慧升级。",
            img:OnlineOrder,
        },
        {
            tag:"Online Service",
            title:"在线服务",
            desc:"协助学校、教育机构和医院精准触达到每一位师生、医生和患者；微信小程序则可将教学、生活和医疗设施的管理线上化。",
            img:OnlineService,
        },
        {
            tag:"Activity Marketing",
            title:"活动营销",
            desc:"通过微信红包、小游戏、店内满减等活动，开展假日、热点等营销活动，增加流量，扩展用户群体。",
            img:ActivityMarketing,
        },
    ],
    page:{
        header:{
            title:"小程序&微网站",
            subTitle:"Mini Program & Wechat website",
            desc:`您可以选择从头开发基于微信深度定制的小程序（最大程度利用微信接口），也可以基于现有手机网站转型为公众号微网站。<br/>
            两种方式都可以让您的应用与微信入口绑定，增加应用访问次数和用户停留时间。`,
        },
        items:{
            main:{
                title:"深度定制",
                subTitle:"In-depth Customization",
                desc:"基于微信API，深度绑定微信能力，包括微信用户、微信消息、微信支付等。",
                img:customization
            },
            minor:[
                {
                    title:"急速体验",
                    subTitle:"Swift",
                    desc:"基于手机和微信性能优化，实现页面急速访问。",
                    img:spide,
                },
                {
                    title:"丰富主题",
                    subTitle:"Rich Themes",
                    desc:"多种应用皮肤可选，可随时动态切换。",
                    img:theme,
                },
                {
                    title:"活动推广",
                    subTitle:"Event Promotion",
                    desc:"针对不同活动，开发不同推广页面。",
                    img:activity,
                },
                {
                    title:"数据统计",
                    subTitle:"Data Statistics",
                    desc:"分析用户轨迹和产品销售信息，调整推广方案。",
                    img:statistics,
                }, 
            ]
        }
    },
    tech:{
        header:{
            title:"商务服务&解决方案",
            subTitle:"Business Service",
            desc:`为用户提升生活体验，为企业提升经营效率，助力行业升级。<br/>面向企业开发细分行业问题解决方案。`,
        },
        items:[
            {
                img:{
                    path:life,
                    direct:'right',
                    directMobile:'right',
                },
                text:{
                    title:"生活服务",
                    subTitle:"Life Service",
                    desc:["智慧电商：替身运营转化率","平台服务：数字化解决方案","旅游：在线精细化运营",]
                }
            },
            {
                img:{
                    path:retail,
                    direct:'left',
                    directMobile:'left',
                },
                text:{
                    title:"智慧零售",
                    subTitle:"Smart Retail",
                    desc:["商超：数字化连接","服饰：线上线下联动","美妆：会员导购一站式营销"]
                }
            },
            {
                img:{
                    path:catering,
                    direct:'right',
                    directMobile:'right',
                },
                text:{
                    title:"智慧餐饮",
                    subTitle:"Smart Catering",
                    desc:["饮品：免排队，轻松点","餐饮：增加顾客粘性","烘焙：打造热点爆品"]
                }
            },
            {
                img:{
                    path:trip,
                    direct:'left',
                    directMobile:'left',
                },
                text:{
                    title:"智慧出行",
                    subTitle:"Smart Trip",
                    desc:["停车：免排队，无感支付","加油站：无感加油，在线充值","酒店：在线看房，在线订房"]
                }
            },
            
        ]
    },
    fee:{
        title:"计费",
        subTitle:"Price",
        img:fee,
        items:[
            {
                tag:"By Page",
                title:"按页面计费",
                desc:"小程序基于本地化服务，功能也界面都比较集中，界面相对较少。",
                price:"2000~4000",
                unit:"元/页",
            },
            {
                tag:"By Time",
                title:"按时间计费",
                desc:"如果您更关心代码质量或对开发工具有特别要求，又或者您有明确的开发进度表，<br/>您可以选择此计费方式，我们将提供详细的开发计划，并对每个开发周期提供价格表。",
                price:"10000",
                unit:"元/月",
            }
        ]
    }
}

export default data;